import { combineReducers, composeReducers, Action } from '@bigcommerce/data-store';

import { clearErrorReducer } from '../common/error';

import { PaymentStrategyAction, PaymentStrategyActionType } from './payment-strategy-actions';
import PaymentStrategyState, { DEFAULT_STATE, PaymentStrategyDataState, PaymentStrategyErrorsState, PaymentStrategyStatusesState } from './payment-strategy-state';

export default function paymentStrategyReducer(
    state: PaymentStrategyState = DEFAULT_STATE,
    action: Action
): PaymentStrategyState {
    const reducer = combineReducers<PaymentStrategyState, PaymentStrategyAction>({
        data: dataReducer,
        errors: composeReducers(errorsReducer, clearErrorReducer),
        statuses: statusesReducer,
    });

    return reducer(state, action);
}

function dataReducer(
    data: PaymentStrategyDataState = DEFAULT_STATE.data,
    action: PaymentStrategyAction
): PaymentStrategyDataState {
    switch (action.type) {
    case PaymentStrategyActionType.InitializeSucceeded:
        return {
            ...data,
            [action.meta && action.meta.methodId]: {
                isInitialized: true,
            },
        };

    case PaymentStrategyActionType.DeinitializeSucceeded:
        return {
            ...data,
            [action.meta && action.meta.methodId]: {
                isInitialized: false,
            },
        };
    }

    return data;
}

function errorsReducer(
    errors: PaymentStrategyErrorsState = DEFAULT_STATE.errors,
    action: PaymentStrategyAction
): PaymentStrategyErrorsState {
    switch (action.type) {
    case PaymentStrategyActionType.InitializeRequested:
    case PaymentStrategyActionType.InitializeSucceeded:
        return {
            ...errors,
            initializeError: undefined,
            initializeMethodId: undefined,
        };

    case PaymentStrategyActionType.InitializeFailed:
        return {
            ...errors,
            initializeError: action.payload,
            initializeMethodId: action.meta && action.meta.methodId,
        };

    case PaymentStrategyActionType.DeinitializeRequested:
    case PaymentStrategyActionType.DeinitializeSucceeded:
        return {
            ...errors,
            deinitializeError: undefined,
            deinitializeMethodId: undefined,
        };

    case PaymentStrategyActionType.DeinitializeFailed:
        return {
            ...errors,
            deinitializeError: action.payload,
            deinitializeMethodId: action.meta && action.meta.methodId,
        };

    case PaymentStrategyActionType.ExecuteRequested:
    case PaymentStrategyActionType.ExecuteSucceeded:
        return {
            ...errors,
            executeError: undefined,
            executeMethodId: undefined,
        };

    case PaymentStrategyActionType.ExecuteFailed:
        return {
            ...errors,
            executeError: action.payload,
            executeMethodId: action.meta && action.meta.methodId,
        };

    case PaymentStrategyActionType.FinalizeRequested:
    case PaymentStrategyActionType.FinalizeSucceeded:
        return {
            ...errors,
            finalizeError: undefined,
            finalizeMethodId: undefined,
        };

    case PaymentStrategyActionType.FinalizeFailed:
        return {
            ...errors,
            finalizeError: action.payload,
            finalizeMethodId: action.meta && action.meta.methodId,
        };

    case PaymentStrategyActionType.WidgetInteractionStarted:
    case PaymentStrategyActionType.WidgetInteractionFinished:
        return {
            ...errors,
            widgetInteractionError: undefined,
            widgetInteractionMethodId: undefined,
        };

    case PaymentStrategyActionType.WidgetInteractionFailed:
        return {
            ...errors,
            widgetInteractionError: action.payload,
            widgetInteractionMethodId: action.meta.methodId,
        };

    default:
        return errors;
    }
}

function statusesReducer(
    statuses: PaymentStrategyStatusesState = DEFAULT_STATE.statuses,
    action: PaymentStrategyAction
): PaymentStrategyStatusesState {
    switch (action.type) {
    case PaymentStrategyActionType.InitializeRequested:
        return {
            ...statuses,
            isInitializing: true,
            initializeMethodId: action.meta && action.meta.methodId,
        };

    case PaymentStrategyActionType.InitializeFailed:
    case PaymentStrategyActionType.InitializeSucceeded:
        return {
            ...statuses,
            isInitializing: false,
            initializeMethodId: undefined,
        };

    case PaymentStrategyActionType.DeinitializeRequested:
        return {
            ...statuses,
            isDeinitializing: true,
            deinitializeMethodId: action.meta && action.meta.methodId,
        };

    case PaymentStrategyActionType.DeinitializeFailed:
    case PaymentStrategyActionType.DeinitializeSucceeded:
        return {
            ...statuses,
            isDeinitializing: false,
            deinitializeMethodId: undefined,
        };

    case PaymentStrategyActionType.ExecuteRequested:
        return {
            ...statuses,
            isExecuting: true,
            executeMethodId: action.meta && action.meta.methodId,
        };

    case PaymentStrategyActionType.ExecuteFailed:
    case PaymentStrategyActionType.ExecuteSucceeded:
        return {
            ...statuses,
            isExecuting: false,
            executeMethodId: undefined,
        };

    case PaymentStrategyActionType.FinalizeRequested:
        return {
            ...statuses,
            isFinalizing: true,
            finalizeMethodId: action.meta && action.meta.methodId,
        };

    case PaymentStrategyActionType.FinalizeFailed:
    case PaymentStrategyActionType.FinalizeSucceeded:
        return {
            ...statuses,
            isFinalizing: false,
            finalizeMethodId: undefined,
        };

    case PaymentStrategyActionType.WidgetInteractionStarted:
    return {
        ...statuses,
        isWidgetInteracting: true,
        widgetInteractionMethodId: action.meta.methodId,
     };

    case PaymentStrategyActionType.WidgetInteractionFinished:
    case PaymentStrategyActionType.WidgetInteractionFailed:
     return {
        ...statuses,
        isWidgetInteracting: false,
        widgetInteractionMethodId: undefined,
     };

    default:
        return statuses;
    }
}
